import {
  HABITS_OF_INTERACTION,
  HABITS_OF_MIND,
  CATEGORY_CATALYTIC_TEACHING_ACTIONS_ELICITS,
  CATEGORY_CATALYTIC_TEACHING_ACTIONS_ORIENTS,
  CATEGORY_TEACHING_ROUTINES,
} from '../../utils/constants';
import SessionAnnotationTimeline from '../SessionAnnotationTimeline';
import SessionEventTimeline from '../SessionEventTimeline';
import SessionMetaData from '../SessionMetaData';
import SessionRoutineTimeline from '../SessionRoutineTimeline';
import SessionTimelineScale from '../SessionTimelineScale';
import SessionTimelineScaleLabels from '../SessionTimelineScaleLabels';


const SessionChart = ({ eventTypes, session }) => {
  const sortBySortOrder = (a, b) => {
    return a.sort_order > b.sort_order;
  }

  return (
    <>
      <SessionMetaData {...{ session }} />
      <div className={'position-relative'}>
        {session && eventTypes && (
          <>
            <SessionTimelineScaleLabels {...{ session }} />
            <SessionTimelineScale {...{ session }} />
            <div className={'border-bottom session-chart-section text-student'}>
              <h5 className={'pt-2 pb-1 ms-2 mb-0 bg-white bg-opacity-50'}>Habits of Mind</h5>
              {eventTypes.filter(type => type.category === HABITS_OF_MIND).toSorted(sortBySortOrder).map(eventType => (
                <SessionEventTimeline key={eventType.key} {...{ eventType, session }} />
              ))}
            </div>
            <div className={'border-bottom session-chart-section text-student'}>
              <h5 className={'pt-2 pb-1 ms-2 mb-0 bg-white bg-opacity-50'}>Habits of Interaction</h5>
              {eventTypes.filter(type => type.category === HABITS_OF_INTERACTION).toSorted(sortBySortOrder).map(eventType => (
                <SessionEventTimeline key={eventType.key} {...{ eventType, session }} />
              ))}
            </div>
            <div className={'border-bottom session-chart-section text-teacher'}>
              <h5 className={'pt-2 pb-1 ms-2 mb-0 bg-white bg-opacity-50'}>
                Teaching Routines
                <span className={'small text-muted ms-2'}>
                  Generates student engagement in mathematically productive thinking and discourse by purposefully:
                </span>
              </h5>
              {eventTypes.filter(type => type.category === CATEGORY_TEACHING_ROUTINES).toSorted(sortBySortOrder).map(eventType => (
                <SessionRoutineTimeline key={eventType.key} {...{ eventType, session }} />
              ))}
            </div>
            <div className={'border-bottom session-chart-section text-teacher'}>
              <h5 className={'pt-2 pb-1 ms-2 mb-0 bg-white bg-opacity-50'}>
                Catalytic Teaching Actions
                <span className={'small text-muted ms-2'}>
                  Uses purposeful, genuine questions and actions to elicit students’:
                </span>
              </h5>
              {eventTypes.filter(type => type.category === CATEGORY_CATALYTIC_TEACHING_ACTIONS_ELICITS).toSorted(sortBySortOrder).map(eventType => (
                <SessionEventTimeline key={eventType.key} {...{ eventType, session }} />
              ))}
            </div>
            <div className={'border-bottom session-chart-section text-teacher'}>
              <h5 className={'pt-2 pb-1 ms-2 mb-0 bg-white bg-opacity-50'}>
                Catalytic Teaching Actions
                <span className={'small text-muted ms-2'}>
                  Orients students to ideas for analysis by focusing discourse to:
                </span>
              </h5>
              {eventTypes.filter(type => type.category === CATEGORY_CATALYTIC_TEACHING_ACTIONS_ORIENTS).toSorted(sortBySortOrder).map(eventType => (
                <SessionEventTimeline key={eventType.key} {...{ eventType, session }} />
              ))}
            </div>
            <div className={'py-2'}>
              <SessionAnnotationTimeline {...{ session }} />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default SessionChart;
