import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';

const SessionEditModal = ({
  session,
  setEditSession,
  setSessions,
}) => {
  const [school, setSchool] = useState(session.school);
  const [teacher, setTeacher] = useState(session.teacher);
  const [c, setC] = useState(session.class);
  const [errors, setErrors] = useState(null);

  const onChangeSchool = (e) => {
    setSchool(e.target.value);
  }

  const onChangeTeacher = (e) => {
    setTeacher(e.target.value);
  }

  const onChangeC = (e) => {
    setC(e.target.value);
  }

  const isFieldInvalid = (fieldName) => {
    return errors && errors.map(error => error.key).includes(fieldName);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setErrors(null);
    setSchool(school.trim());
    setTeacher(teacher.trim());
    setC(c.trim());
    const config = {
      url: `${process.env.REACT_APP_API_BASE_URL}/api/sessions/${session.id}`,
      method: 'PUT',
      data: { school, teacher, class: c },
    };
    axios.request(config)
      .then(response => {
        setSessions(sessions => sessions.map(s => {
          if (s.id === session.id) {
            return {
              ...session,
              school,
              teacher,
              class: c,
            };
          } else {
            return s;
          }
        }));
        setEditSession(null);
      })
      .catch(error => {
        if (error.response) {
          const { response } = error;
          const { data, status } = response;
          console.log(data)
          if (status >= 500) {
            setErrors([{ key: null, message: `A System Error occurred (Status Code ${status})`}]);
          } else {
            if (status === 422) {
              const errorsArray = [];
              Object.keys(data.errors).forEach(key => {
                data.errors[key].forEach(message => {
                  errorsArray.push({ key, message });
                });
              });
              setErrors(errorsArray);
            } else {
              setErrors([{ key: null, message: `You are not logged in (Status Code ${status})`}]);
            }
          }
        } else {
          setErrors([{ key: null, message: 'A System Error occurred'}]);
        }
        console.log(error);
      })
  }

  return (
    <Modal show onHide={() => setEditSession(null)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className={'mt-3'}>
            <Form.Label className={ isFieldInvalid('school') ? 'text-danger' : '' }>
              School
            </Form.Label>
            <Form.Control
              onChange={onChangeSchool}
              value={school}
              className={ isFieldInvalid('school') ? 'is-invalid' : '' }
            />
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label className={ isFieldInvalid('teacher') ? 'text-danger' : '' }>
              Teacher
            </Form.Label>
            <Form.Control
              onChange={onChangeTeacher}
              value={teacher}
              className={ isFieldInvalid('teacher') ? 'is-invalid' : '' }
            />
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label className={ isFieldInvalid('class') ? 'text-danger' : '' }>
              Class
            </Form.Label>
            <Form.Control
              onChange={onChangeC}
              value={c}
              className={ isFieldInvalid('class') ? 'is-invalid' : '' }
            />
          </Form.Group>
          <Form.Group className={'float-end mt-4'}>
            <Button onClick={() => setEditSession(null)} variant={'light'}>Cancel</Button>
            <Button variant={'primary'} className={'ms-3'} type={'submit'}>Submit</Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default SessionEditModal;
