import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import {
  Col,
  Row,
} from 'react-bootstrap';
import SessionList from '../SessionList';

const Dashboard = () => {
  const { auth } = useOutletContext();
  const [sessions, setSessions] = useState(null);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/sessions`;
    axios.get(url)
      .then(response => {
        setSessions(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  return (
    <Row>
      <Col>
        <h1>Dashboard for {auth.user.name}</h1>
        <h3>Your Sessions:</h3>
        {sessions && (
          <SessionList {...{ sessions, setSessions, user: null }} />
        )}
      </Col>
    </Row>
  );
}

export default Dashboard;
