import { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import axios from 'axios';

const SessionDeleteModal = ({
  session,
  setDeleteSession,
  setSessions,
}) => {
  const [error, setError] = useState(null);

  const deleteSession = () => {
    setError(null);
    const config = {
      url: `${process.env.REACT_APP_API_BASE_URL}/api/sessions/${session.id}`,
      method: 'DELETE'
    };
    axios.request(config)
      .then(response => {
        setSessions(sessions => sessions.filter(s => s.id !== session.id));
        setDeleteSession(null);
      })
      .catch(error => {
        setError('A System Error Occurred');
        console.log(error);
      })
  }

  return (
    <Modal show onHide={() => setDeleteSession(null)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant={'danger'}>{ error }</Alert>
        )}
        <p>Are you sure you want to delete this session?</p>
        <Button onClick={() => setDeleteSession(null)} variant={'light'}>Cancel</Button>
        <Button variant={'danger'} className={'ms-3'} onClick={deleteSession}>Delete</Button>
      </Modal.Body>
    </Modal>
  );
}

export default SessionDeleteModal;
